import React, { useState, useEffect } from 'react';
import { Map, View } from 'ol';
import 'ol/ol.css';
import { GeoJSON } from 'ol/format';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { defaults } from 'ol/interaction';
import {Button, Col, Row, Space} from "antd";
import PermissionChecker from "../../PermissionChecker";
import http from "../../http";
import {useTranslation} from "react-i18next";
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

proj4.defs(
    'EPSG:6675',
    '+proj=tmerc +lat_0=36 +lon_0=137.1666666666667 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'
);
register(proj4);

const image = new CircleStyle({
    radius: 5,
    fill: null,
    stroke: new Stroke({ color: '#DC000C', width: 1 }),
});

const styles = {
    'Point': new Style({
        image: image,
    }),
    'LineString': new Style({
        stroke: new Stroke({
            color: '#079327',
            width: 1,
        }),
    }),
    'MultiLineString': new Style({
        stroke: new Stroke({
            color: '#079327',
            width: 1,
        }),
    }),
    'MultiPoint': new Style({
        image: image,
    }),
    'MultiPolygon': new Style({
        stroke: new Stroke({
            color: 'yellow',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 0, 0.1)',
        }),
    }),
    'Polygon': new Style({
        stroke: new Stroke({
            color: '#377EF3',
            lineDash: [4],
            width: 3,
        }),
        fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
        }),
    }),
    'GeometryCollection': new Style({
        stroke: new Stroke({
            color: 'magenta',
            width: 2,
        }),
        fill: new Fill({
            color: 'magenta',
        }),
        image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
                color: 'magenta',
            }),
        }),
    }),
    'Circle': new Style({
        stroke: new Stroke({
            color: '#DC000C',
            width: 2,
        }),
        fill: new Fill({
            color: 'rgba(255,0,0,0.2)',
        }),
    }),
};

const styleFunction = function (feature) {
    return styles[feature.getGeometry().getType()];
};

const DXFViewer = (props) => {
    const [map, setMap] = useState(null);
    const [geojsonData, setGeojsonData] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if(props.geojsonUrl) {
            http.get(props.geojsonUrl)
                .then(response => {
                    setGeojsonData(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [props.geojsonUrl]);

    useEffect(() => {
        if(geojsonData) {
            const target = 'map-container';
            const interactions = defaults({mouseWheelZoom:false, pinchRotate: false, pinchZoom: false, dragPan: false});
            const controls = [];
            const projection = 'EPSG:6675';
            const center = [0, 0];
            const zoom = 5;

            const mapObject = new Map({
                target,
                interactions,
                controls,
                view: new View({
                    projection,
                    center,
                    zoom,
                }),
            });
            const vectorSource = new VectorSource({
                features: new GeoJSON().readFeatures(geojsonData),
                format: new GeoJSON(),
            })

            const vectorLayer = new VectorLayer({
                source: vectorSource,
                style: styleFunction,
            });
            mapObject.addLayer(vectorLayer);
            mapObject.getView().fit(vectorSource.getExtent());

            setMap(mapObject);
            return () => mapObject.setTarget(null);
        }

    }, [geojsonData]);

    const uploadHandler = ()=>{
        if(props.handleUploadButtonClick){
            props.handleUploadButtonClick()
        }
    }

    const downloadHandler = () => {
        http.get(props.downloadURL).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', props.downloadFileName || 'file.dxf');
            document.body.appendChild(link);
            link.click();
        }).catch((err)=>{
            console.log(err)
        });

    };

    return (
        <>
            <Row style={{justifyContent: 'space-between', alignItems:'start', marginBottom:'10px'}}>
                <Col style={{fontWeight:"bold", fontSize: 15}}>
                    {props.title}
                </Col>
                <Col>
                    <Space>
                        {props.downloadURL ? <PermissionChecker requiredPermissions={props.requiredPermission || []}>
                            <Button
                                icon={<ArrowDownOutlined/>}
                                className={'bordered-default-button'}
                                onClick={()=>downloadHandler()}
                            >
                                 {t('download_button_text')}
                            </Button>
                        </PermissionChecker>:<></>}
                        {!props.hideUploadButton ? <PermissionChecker requiredPermissions={props.requiredPermission || []}>
                            <Button
                            icon={<ArrowUpOutlined/>}
                            type={"primary"}
                            style={{border: "none"}}
                            onClick={()=>uploadHandler()}>
                                {t('upload_button_text')}
                            </Button>
                        </PermissionChecker>:null}
                    </Space>
                </Col>
            </Row>
            <Row style={{height: '100%'}}>
                <div id="map-container" style={{height: '100%', width: '100%'}} />
            </Row>
        </>
    )
};

export default DXFViewer;