import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";

const ManifestAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, project, duplicate, reload})=>{
    const [initialValues, setInitialValues] = useState({project: project.id, machine_type: 1, construction_type: 1});
    const defaultValues = {project: project.id, machine_type: 1, construction_type: 1, positioning_method: 0};
    const [hiddenCompactionCount, setHiddenCompactionCount] = useState(true)
    const [hiddenTileSize, setHiddeTileSize] = useState(true)
    const { t } = useTranslation();
    const config = {
        addUrl: !duplicate ? '/api/v1/projects/manifests/add/': '/api/v1/projects/manifests/duplicate/',
        editUrl: edit ? `/api/v1/projects/manifests/${editItem?.id}/edit/` : '',
        dataUrl: edit ? `/api/v1/projects/manifests/${editItem?.id}/` : '',
        pageTitle: !edit ? !duplicate ? t('manifest_addpage_title', {project_name: project.project_name}):t('manifest_duplicatepage_title', {project_name: project.project_name}): t('addpage_edit_button_text') + ' ' +`${editItem?.name || ''}`,
        colNo: 2,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: !edit ? !duplicate ? t('addpage_save_button_text'): t('duplicate_button_text'): t('addpage_edit_button_text'),
        addSuccessMessage: !edit ? !duplicate ? t('manifest_add_successful_text'): t('manifest_duplicate_successful_text') : t('manifest_edit_successful_text'),
        modalSize: 800,
        fields: [
            {
                name: 'name',
                label: t('manifest_name_field'),
                placeholder: t('manifest_name_field_placeholder'),
                isRequired: true,
                requiredMessage: t('manifest_name_required_text'),
                type: 'input',
                colNo: 1,
                order: 1
            },
            {
                name: 'construction_type',
                label: t('manifest_constructiontype_field'),
                placeholder: t('manifest_constructiontype_field'),
                isRequired: false,
                requiredMessage: '',
                type: 'select',
                extraProps: {
                    url: '/api/v1/enums/ManifestChoices/combo/',
                    withTranslation: true,
                },
                colNo: 1,
                order: 2
            },
            {
                name: 'machine_type',
                label: t('manifest_machinetype_field'),
                placeholder: t('manifest_machinetype_field'),
                isRequired: false,
                type: 'select',
                extraProps: {
                    url: '/api/v1/enums/MachineTypeChoices/combo/',
                    withTranslation: true,
                    onSelect:(value, childrens) => {
                        if(value === 2){
                            setHiddeTileSize(false);
                            setHiddenCompactionCount(false);
                        }
                        else{
                            setHiddeTileSize(true);
                            setHiddenCompactionCount(true);
                        }
                    },
                    disabled: edit,
                },
                requiredMessage: '',
                colNo: 1,
                order: 3,

            },
            {
                name: 'positioning_method',
                label: t('manifest_positioningmethod_field'),
                placeholder: t('manifest_positioningmethod_field_placeholder'),
                isRequired: false,
                type: 'select',
                extraProps: {
                    url: '/api/v1/enums/ManifestPositionMethodChoices/combo/'
                },
                requiredMessage: '',
                colNo: 1,
                order: 4
            },
            {
                name: 'compaction_count',
                label: t('manifest_compactioncount_field'),
                placeholder: t('manifest_compactioncount_field'),
                isRequired: false,
                requiredMessage: '',
                colNo: 1,
                order: 5,
                hidden:  hiddenCompactionCount,
            },
            {
                name: 'tile_size',
                label: t('manifest_tile_size_field'),
                placeholder: t('manifest_tile_size_field'),
                isRequired: false,
                requiredMessage: '',
                colNo: 1,
                order: 5,
                hidden:  hiddenTileSize,
            },
            {
                name: 'reference',
                label: t('manifest_reference_field'),
                placeholder: t('manifest_reference_field_placeholder'),
                isRequired: false,
                requiredMessage: 'Reference Point File Needed',
                type: 'file',
                colNo: 2,
                order: 1
            },
            {
                name: 'localization',
                label: t('manifest_localization_field'),
                placeholder: t('manifest_localization_field_placeholder'),
                isRequired: false,
                requiredMessage: 'Localization File Required',
                type: 'file',
                colNo: 2,
                order: 2
            },
            {
                name: 'site_data_2d',
                label: t('manifest_dxf_field'),
                placeholder: t('manifest_dxf_file_placeholder'),
                isRequired: false,
                requiredMessage: '2D Site Data Required',
                type: 'file',
                colNo: 2,
                order: 3
            },
            {
                name: 'site_data',
                label: t('manifest_xml_fields'),
                placeholder: t('manifest_xml_fields'),
                isRequired: false,
                requiredMessage: '3D Site Data Required',
                type: 'file',
                colNo: 2,
                order: 4
            },
            {
                name: 'site_data_sub',
                label: t('manifest_sub_xml_field'),
                placeholder: t('manifest_sub_xml_field'),
                isRequired: false,
                requiredMessage: '3D Site Data Sub Required',
                type: 'file',
                colNo: 2,
                order: 5
            }
        ]
    }

    const onFinish=(values, handleError, form)=>{
        const formData = new FormData();
        formData.append('project', project.id);
        const requestConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        for (let name in values) {
            const value = values[name];
            if (value instanceof File ) {
                formData.append(name, value, value.name);
            }
            // Append other values to the FormData object
            else if(value !== 0 && (!value || value === 'undefined' || value === 'null')) {
                formData.append(name, '');
            }
            else{
                formData.append(name, value);
            }

        }
        if (!edit) {
            if(duplicate && editItem){
                formData.append('original_id', editItem.id)
            }
            http.post(config.addUrl, formData, requestConfig).then((resp) => {
                if (resp.status === 201) {
                    message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                    if (onSuccessSubmit) {
                        onSuccessSubmit()
                        form.resetFields();
                        form.setFieldsValue(defaultValues);
                    } else if (onClose) {
                        onClose()
                        form.resetFields();
                        form.setFieldsValue(defaultValues);
                    }
                }
            }).catch((error) => {
                handleError(error);
                if (onErrorSubmit) {
                    onErrorSubmit()
                }
            })
        }
        else{
                    http.patch(config.editUrl, formData).then((resp) => {
                        if (resp.status === 200) {
                            message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                            if (onSuccessSubmit) {
                                onSuccessSubmit()
                                form.resetFields();
                                form.setFieldsValue(defaultValues);
                            } else if (onClose) {
                                onClose()
                                form.resetFields();
                                form.setFieldsValue(defaultValues);
                            }
                        }
                    }).catch((error) => {
                        handleError(error);
                        if (onErrorSubmit) {
                            onErrorSubmit()
                        }
                    })
                }
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/projects/manifests/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                let data = resp.data;
                if(duplicate) {
                    data.name = data.name + ' Copy'
                }
                data.localization = resp.data.localization_file_name;
                data.reference = resp.data.reference_file_name;
                data.site_data = resp.data.site_data_file_name;
                data.site_data_sub = resp.data.site_data_sub_file_name;
                data.site_data_2d = resp.data.site_data_2d_file_name;
                setInitialValues(resp.data);
                if(data.machine_type === 2){
                    setHiddeTileSize(false);
                    setHiddenCompactionCount(false);
                }
                else{
                    setHiddeTileSize(true);
                    setHiddenCompactionCount(true);
                }
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal onFinish={onFinish} config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues} defaultValues={defaultValues}/>

}

export default ManifestAddPage;