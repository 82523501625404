import {Divider, Row, Col, Space, Table, message, Button} from "antd";
import React, {useEffect, useState} from "react";
import http from "../../http";
import {useSelector} from "react-redux";
import MachineListPage from "../Machines/MachineListPage";
import {useTranslation} from "react-i18next";
import PasswordChangePage from "./PasswordChange";

const UserProfilePage=(props)=>{
    const [user, setUser] = useState({})
    const auth = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const fetchUserData=()=>{
        let userID = auth.user_id;
        http.get(`/api/v1/auth/users/${userID}/profile/`).then((resp)=>{
            setUser(resp.data)
        }).catch((err)=>{
            message.error(t('error_fetching_user_profile'))
        })
    }

    useEffect(()=>{
        fetchUserData()
    }, [])

    const buildBreadcrumbs=()=>{
        return t('menu_0');
    }
    const renderPasswordChangeForm = () => {
        return (
            <PasswordChangePage
                visible={isModalVisible}
                onClose={()=>{setIsModalVisible(false)}}
            />
        );
    };

    return(
        <>
            {renderPasswordChangeForm()}
            <div style={{overflowY:'auto', overflowX:'hidden', width: '100%', height:'100%'}}>
                <Row style={{height: '5%', color: "black", fontWeight:'bold', justifyContent:'space-between'}}>
                    <Col>
                        <Space>{buildBreadcrumbs()}</Space>
                    </Col>
                    <Col>
                        {user.is_initial === 0 ?<Button
                            type={'primary'}
                            onClick={() => setIsModalVisible(true)}>{t('profile_change_password_button_text')}
                        </Button>:null}
                    </Col>
                </Row>
                <Row style={{display: 'flex', flexDirection:'column'}}>
                    <Col style={{lineHeight: '1.5rem'}}><h1>{user.full_name}</h1></Col>
                    <Col>{t('staff_email_field')} : {user.email}</Col>
                    <Col>{t('staff_mobile_field')} : {user.mobile}</Col>
                    <Col>{t('staff_slackid_field')} : {''}</Col>
                </Row>
                <Divider />
                <Row style={{overflow: "hidden", height: '60%'}}>
                    <div style={{width: '100%', height: '100%'}}>
                         <MachineListPage url={`/api/v1/machines/?operator=${auth.user_id}`} title={t('my_machine_list_title')}/>
                    </div>
                </Row>
            </div>
        </>

    )
}

export default UserProfilePage;
