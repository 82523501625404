import React, {useEffect, useState, useRef} from 'react';
import 'ol/ol.css';
import {Button, Col, Row, Space} from "antd";
import http from "../../http";
import PermissionChecker from "../../PermissionChecker";
import {useTranslation} from "react-i18next";
import THREEGL from "../../utilities/THREEGL";
import XMLParser from "../../utilities/XMLParser";
import {
FullscreenOutlined,
ArrowUpOutlined,
ArrowDownOutlined
} from '@ant-design/icons';

const XMLViewer = (props) => {
    const { t } = useTranslation();
    const [xmlData, setXMLData] = useState(null);
    const GL_MAP = useRef();
    const GL_HOLDER = useRef();
    const [threeGL, setThreeGL] = useState(null);

    useEffect(()=>{
        const fetchData=()=> {
            http.get(props.downloadURL)
                .then((response) => {
                        setXMLData(response.data);
                    }
                ).catch((err) => {
            });
        }
        if(props.downloadURL && !xmlData) {
            fetchData()
        }
    }, [props.downloadURL])

useEffect(()=>{
    if(xmlData && !threeGL){
        const threeGLElem = new THREEGL(GL_MAP.current.clientWidth, GL_MAP.current.clientHeight, GL_HOLDER
            .current, props.id);
        setThreeGL(threeGLElem);
        const xmlContent = new XMLParser(xmlData).GetGLObject();
        threeGLElem.LoadSurfaceXMLMaps(xmlContent);
        threeGLElem.UpdateGLSize(GL_MAP.current.clientWidth, GL_MAP.current.clientHeight);
        threeGLElem.ZoomToFitSurface();
    }
}, [xmlData])

    const uploadHandler = ()=>{
        if(props.handleUploadButtonClick){
            props.handleUploadButtonClick()
        }
    }

    const downloadHandler = () => {
        const url = window.URL.createObjectURL(new Blob([xmlData], { type: 'application/xml' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', props.downloadFileName || 'file.xml');
        document.body.appendChild(link);
        link.click();
    };

const handleZoomtoFitCall=(e)=>{
    if(threeGL){
        console.log(threeGL);
        threeGL.ZoomToFitSurface()
    }
}

    return (
        <>
            <Row style={{justifyContent: 'space-between', alignItems:'start', marginBottom:'10px'}}>
                <Col style={{fontWeight:"bold", fontSize: 15}}>
                    {props.title}
                </Col>
                <Col>
                    <Space>
                        {props.downloadURL ? <PermissionChecker requiredPermissions={props.requiredPermission || []}>
                            <Button
                                icon={<ArrowDownOutlined />}
                                className={'bordered-default-button'}
                                onClick={()=>downloadHandler()}>
                                {t('download_button_text')}
                            </Button>
                        </PermissionChecker>:<></>}
                        {!props.hideUploadButton ?<PermissionChecker requiredPermissions={props.requiredPermission || []}>
                            <Button
                                icon={<ArrowUpOutlined />}
                                type={"primary"}
                                style={{border: "none"}}
                                onClick={()=>uploadHandler()}>
                                {t('upload_button_text')}
                            </Button>
                        </PermissionChecker>:null}
                    </Space>
                </Col>
            </Row>
            <div id={`_main${props.id}`} ref={GL_MAP} style={{width:"100%", height:"100%", position:'relative'}}>
                <div id={`_holder${props.id}`} ref={GL_HOLDER} style={{height:"80%"}}></div>
                <div id={props.id} style={{position:'absolute', top:0, left:0}}></div>
                <div style={{position:'absolute', top:0, right:0}}>
                    <Button
                    type="button"
                    onClick={(e)=>handleZoomtoFitCall(e)}
                    icon={<FullscreenOutlined />}
                  />
                </div>
            </div>

        </>
    )
};

export default XMLViewer;