import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout} from "../../actions/actions";
import {useData} from "../../DataContext";
import {useTranslation} from "react-i18next";


const GroupAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, modalSize, reload})=>{
    const [initialValues, setInitialValues] = useState({});
    const { t } = useTranslation();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {sharedData, setData} = useData()

    const config = {
        addUrl: '',
        editUrl: '',
        dataUrl: '',
        pageTitle: t('password_change_form_title'),
        colNo: 2,
        cancelButtonText: t('password_change_cancel_button_text'),
        saveButtonText: t('password_change_button_text'),
        addSuccessMessage: t('password_changed_successful_text'),
        modalSize: modalSize || 300,
        fields: [
            {
                name: 'old_password',
                label: t('password_change_old_password_label'),
                placeholder: t('password_change_old_password_placeholder'),
                isRequired: true,
                requiredMessage: t('password_change_old_password_required_message'),
                type: 'password',
                colNo: 1,
                order: 1,
            },
            {
                name: 'new_password',
                label: t('password_change_new_password_label'),
                placeholder: t('password_change_new_password_placeholder'),
                isRequired: true,
                requiredMessage: t('password_change_new_password_required_message'),
                type: 'password',
                colNo: 1,
                order: 2
            }
        ]
    }

    const handleLogout = ()=>{
        http.post('/o/logout/').then((resp)=>{
            setData({})
            dispatch(logout());
            navigate('/login');
        }).catch((err)=>{
            setData({})
            dispatch(logout());
            navigate('/login');
        })
    }

    const onFinish=(values, handleError, form)=>{
        http.post('/o/change_password/', values).then((resp) => {
            if (resp.status === 200) {
                message.success(config.addSuccessMessage);
                form.resetFields();
                handleLogout();
            }
        }).catch((error) => {
            handleError(error);
            if (onErrorSubmit) {
                onErrorSubmit()
            }
        })
    }

    return <AddPageModal
        config={config}
        visible={visible}
        onFinish={onFinish}
        onClose={onClose}
    />

}

export default GroupAddPage;