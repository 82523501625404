import {Divider, Row, Col, Space, Button, Typography, message} from "antd";
import ManifestListPage from "../Manifests/ManifestListPage";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import http from '../../http';
import ProjectMachineListPage from "./ProjectMachineListPage";
import MachineAssignmentAddPage from "./MachineAssignmentAddPage";
import DataTable from "../../components/DataTable/DataTable";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";
import {getReadableTimeFromTimeStamp, getScreenSize} from '../../utilities/common'
import DeleteIcon from "../../components/CustomIcons/DeleteIcon";
import EditPencilIcon from "../../components/CustomIcons/EditPencilIcon";

const ProjectDetailPage=(props)=>{
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {state} = location;
    const [project, setProject] = useState(state?.project);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [edit, setEditAssignment] = useState(false)
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isValidState, setValidState] = useState(!!state?.project);

    const fetchProjectData=()=>{
        http.get(`/api/v1/projects/${state?.project?.id}/`).then((resp)=>{
            setLoading(false);
            setProject(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found',{replace: true})
        }
        if(isValidState) {
            fetchProjectData();
        }
    }, [state])

    const handleAssignmentEdit = (record)=>{
        setEditAssignment(true);
        setEditItem(record);
        setIsModalVisible(true);
    }

    const renderEditIcon=(record)=>{
        if(record.status !== 2){
            return <Typography.Link onClick={()=>handleAssignmentEdit(record)}><EditPencilIcon /></Typography.Link>
        }
        return <Typography.Link style={{color:'grey'}}><EditPencilIcon /></Typography.Link>
    }

    const renderDeleteIcon=(record)=>{
        if(record.status !== 2){
            return <CustomConfirmPopup
                        title={t('machine_usagerecord_delete_confirmation_text')}
                        onConfirm={()=>handleAssignmentDelete(record)}
                        icon={<DeleteIcon/>}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        disabled={false}
                        okType='default'
                    >
                        <Typography.Link><DeleteIcon/></Typography.Link>
                    </CustomConfirmPopup>
        }
        return <Typography.Link style={{color:'grey'}}><DeleteIcon /></Typography.Link>
    }

    const handleAssignmentDelete=(record)=>{
        http.delete(`/api/v1/projects/machine-assignments/${record.id}/delete/`).then((resp)=>{
            if(resp.status === 204){
                message.success(t('machine_usagerecord_delete_successful_text'));
                fetchProjectData();

                setReload(!reload)
                // fetchDefaultData();
            }
            else{
                message.error(t('machineservice_uage_record_delete_failed_text'));
            }
        })
    }
    const columns = [
        {
            title: t('machine_usagerecord_list_col1'),
            dataIndex: 'from_date',
            key: 'from_date',
            render: (value) => {
                let readable_timestamp = getReadableTimeFromTimeStamp(value, 'YYYY-MM-DD HH:mm');
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title: t('machine_usagerecord_list_col2'),
            dataIndex: 'to_date',
            key: 'to_date',
            render: (value) => {
                let readable_timestamp = getReadableTimeFromTimeStamp(value, 'YYYY-MM-DD HH:mm');
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title: t('machine_usagerecord_list_col3'),
            dataIndex: 'machine_text',
            key: 'machine_text',
        },
        {
            title: t('machine_usagerecord_list_col4'),
            dataIndex: 'manifest_text',
            key: 'manifest_name',
        },
        {
            title: t('machine_usagerecord_list_col5'),
            dataIndex: 'status_text',
            key: 'status_text',
            render: (text) => (
                <div>{t(text)}</div>
            ),
        },
        {
            title: t('machine_usagerecord_list_col6'),
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    {renderEditIcon(record)}
                    {renderDeleteIcon(record)}
                </Space>
            )
        },
    ]

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state?.breadcrumbs){
            state?.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{project.project_name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/projects'}>{t('project_list_title')}  </Link> >  {project.project_name}</>;
    }
    const handleSuccessAdd = () =>{
        setEditAssignment(false);
        setEditItem(null);
        setIsModalVisible(false);
        fetchProjectData();
        // fetchDefaultData();
        setLoading(!!loading);
        setReload(!reload)
    }
    const renderAssignmentForm = () => {
        return (
            <MachineAssignmentAddPage
                visible={isModalVisible}
                onClose={()=>{setEditItem(null); setEditAssignment(false); setIsModalVisible(false)}}
                project={project}
                edit={edit}
                editItem={editItem}
                onSuccessSubmit={handleSuccessAdd}
            />
        );
    };
    if(isValidState) {
        return (
            <>
                {renderAssignmentForm()}
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                        <Col>
                            <PermissionChecker requiredPermissions={['project-machine-assignment-create']}>
                                <Button type={'primary'} style={{background: '#377EF3'}}
                                        onClick={() => setIsModalVisible(true)}>{t('machine_manifest_schedule_button_text')}</Button>
                            </PermissionChecker>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{project.project_name}</h1></Col>
                        <Col>{t('project_duration_field')}: {project.duration}</Col>
                        <Col>{t('project_sitemanager_field')}: {project.site_manager_name}</Col>
                    </Row>

                    <Divider/>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <ManifestListPage reload={!!reload} pageSize={getScreenSize() === 'xl' ? 4 : 3}
                                              project={project} link={[{
                                to: '/projects/',
                                state: {},
                                title: t('project_list_title')
                            }, {to: '/project/detail/', state: {project: project}, title: project.project_name}]}/>
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <ProjectMachineListPage
                                pageSize={getScreenSize() === 'xl' ? 4 : 3}
                                project={project}
                                link={[{
                                    to: '/projects/',
                                    state: {},
                                    title: t('project_list_title')
                                }, {to: '/project/detail/', state: {project: project}, title: project.project_name}]}
                                reload={!!reload}
                                title={t('allocated_machine_listpage_title')}
                            />
                        </div>
                    </Row>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <DataTable
                            reload={!reload}
                            pageTitle={t('machine_usagerecord_listpage_title')}
                            columns={columns}
                            url={`/api/v1/projects/machine-assignments/?project=${project.id}`}
                            pagination={true}
                            pageButtons={[]}
                        />
                    </Row>
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default ProjectDetailPage;