import React from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import { Button, Result, Space } from 'antd';
import http from './http';
import {useData} from "./DataContext";
import store from "./store";
import {logout} from "./actions/actions";

const PermissionDenied = () => {

  const navigate = useNavigate();
  const {sharedData, setData} = useData()
  const dispatch = useDispatch();
  const handleLogout = ()=>{
    http.post('/o/logout/').then((resp)=>{
        setData({})
        dispatch(logout());
        navigate('/login');
    }).catch((err)=>{
        setData({})
        dispatch(logout());
        navigate('/login');
    })
    }
  return <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={
        <Space>
           <Button type="primary" onClick={()=> navigate('/',{replace: true})}>Back Home</Button>
            <Button type="default" onClick={handleLogout}>Logout</Button>
        </Space>
        }
  />;
};

export default PermissionDenied;
