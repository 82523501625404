import { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    Button,
    Input,
    Pagination,
    Divider,
    message,
    Typography,
    Space,
    Tooltip
} from 'antd';
import http from '../../http';
import {
    SearchOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import SelectField from "../Fields/SelectField";
import EditPencilIcon from "../CustomIcons/EditPencilIcon";
import DeleteIcon from "../CustomIcons/DeleteIcon";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../CustomConfirmPopup/CustomConfirmPopup";
import React from 'react';

const { Meta } = Card;

const ListPage = (
    {
        config,
        reload=false,
        onAddClick=null,
        onEditClick=null,
        onDeleteClick=null,
        onSearchClick=null,
        onDetailClick=null,
        onPageChange=null
    }
) => {
    const [listData, setListData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [orderByValue, setOrderByFiled] = useState('');
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCard, setSelectedCard] = useState(null);
    const [pageSize, setPageSize] = useState( 10); // Number of
    // cards per page
    const { t } = useTranslation();
    const defaultPageSizes = [10, 15, 20, 25, 50];
    const pageSizeOptions = config.pageSize ? [config.pageSize, ...defaultPageSizes.filter(size => size !== config.pageSize)] : defaultPageSizes;

    const fetchData = (page = 1, pageSize=10, searchValue) => {
        setLoading(true);
        let params = {
            page,
            page_size: localStorage.getItem(config.pageTitle) || config.pageSize || pageSize,
            ordering: orderByValue
        }
        if(searchValue){
            params['search'] = searchValue
        }
        http.get(config.url, {
            params: params,
        }).then((response)=>{
            setListData(response.data.results);
            setTotal(response.data.count);
            setLoading(false)
        }).catch((error)=> {
                setLoading(false)
            }
        )
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage, reload]);


    const handleSearchClick=()=>{
        if(!onSearchClick){
            fetchData(currentPage, localStorage.getItem(config.pageTitle) || config.pageSize || pageSize, searchValue)
        }
        else{
            onSearchClick(searchValue)
        }
    }

    const handleAddButtonClicked = () => {
        if(!onAddClick){
            message.error(t('not_implemented_error_text'))
        }
        else{
            onAddClick()
        }
    }

    const handleEditButtonClicked = (item) =>{
        if(!onEditClick){
            message.error(t('not_implemented_error_text'))
        }
        else{
            onEditClick(item)
        }
    }

    const onDetailButtonClicked = (item)=>{
        if(!onDetailClick){
            message.error(t('not_implemented_error_text'))
        }
        else{
            onDetailClick(item)
        }
    }

    const onDeleteButtonClicked = (item)=>{
        if(!onDeleteClick){
            message.error(t('not_implemented_error_text'))
        }
        else{
            onDeleteClick(item)
        }
    }

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        localStorage.setItem(config.pageTitle, pageSize)
        fetchData(currentPage, pageSize, searchValue);
    };

    const handleCardSelect = (item) => {
        if(selectedCard?.id === item.id){
            setSelectedCard(null)
        }
        else{
            setSelectedCard(item)
        }
    }

    const onOrderByFieldSelect=(value, children)=>{
        setOrderByFiled(value);
        fetchData();
    }

    const itemRender = (page, type, originalElement) => {
        if (type === 'page') {
            return (
                <span
                    className={`custom-page-item ${page === currentPage ? 'current' : ''}`}
                >
          {page}
        </span>
            );
        }
        if (type === 'prev' || type === 'next') {
            return (
                <span className="custom-page-item">
          {originalElement}
        </span>
            );
        }
        return originalElement;
    };

    const renderHeader = () => {
        let childContent = null;
        let content = (
            <Row justify="space-between" align="middle" gutter={[16, 16]} key="header">
                <Col key="pageTitle" xs={24} sm={24} md={12} lg={12} xl={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Space size="middle">
                        <div style={{ fontWeight: "bold", fontSize: "30px" }}>{config.pageTitle}</div>
                        <div>{config.pageSubtitle || t('generic_listpage_subtitle')}: {total}</div>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div style={{ whiteSpace: 'nowrap' }}>
                        <Pagination
                            pageSizeOptions={pageSizeOptions}
                            size="small"
                            current={currentPage}
                            total={total}
                            onChange={handlePageChange}
                            defaultPageSize={localStorage.getItem(config.pageTitle) || config.pageSize || pageSize}
                            showSizeChanger
                            onShowSizeChange={onShowSizeChange}
                            itemRender={itemRender}
                            showLessItems
                            responsive={true}
                            locale={{
                                items_per_page: '/ Page',
                            }}
                        />
                    </div>
                </Col>
                <Col key="searchArea" xs={24} sm={24} md={24} lg={8} xl={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <SelectField data={config.orderingFields} placeholder="Order By" onSelect={onOrderByFieldSelect} />
                    <Input placeholder={config.searchPlaceHolder} suffix={<SearchOutlined onClick={handleSearchClick} />} style={{ marginLeft: 16}} onChange={(e) => setSearchValue(e.target.value)} />
                    {!config.hideAddButton ? (
                        <PermissionChecker requiredPermissions={config.addPermission || []}>
                            <Button type="primary" shape="circle" icon={<PlusOutlined />} style={{ marginLeft: 16, background: '#377EF3' }} onClick={handleAddButtonClicked} />
                        </PermissionChecker>
                    ) : null}
                </Col>
            </Row>
        );
        if(config.buildPageHeader){
            childContent = config.buildPageHeader();
        }
        return childContent || content;
    }

    const getCardBackgroundColor = (item, index)=>{
        if(config.getCardBackgroundColor){
            return config.getCardBackgroundColor(item)
        }
    }

    const buildCardHeader = (item, index)=>{
        let childContent = null;
        let content = <Row className={'cardHeader'} style={{alignItems:'center'}} key={'cardHeader'}>
            <Tooltip title={item? item[config.cardTitleField]: ''} placement={'bottomLeft'}>
                <Col key={'cardTitle'} span={16} style={{whiteSpace: 'nowrap', fontWeight: 'bold', fontSize:'20px', overflow:'hidden', textOverflow:'ellipsis'}}>{item? item[config.cardTitleField]: ''}</Col>
            </Tooltip>
            <Col key={'cardButtons'} span={8} style={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
                {!config.hideEdit ?
                    <PermissionChecker requiredPermissions={config.editPermission || []}>
                        <Button className={'card-icon card-edit-icon'} icon={<EditPencilIcon />} style={{border: 'none'}} onClick={()=>handleEditButtonClicked(item)}/>
                    </PermissionChecker> : <div></div>}
                {!config.disableDelete ?
                    <CustomConfirmPopup
                        title={config.deleteTitle || "Are you sure to delete this instance?"}
                        onConfirm={()=>onDeleteButtonClicked(item)}
                        icon={<DeleteIcon />}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        okType='default'
                    >
                        <PermissionChecker
                            requiredPermissions={config.deletePermission || []}>
                            <Button
                            className={'card-icon card-edit-icon'}
                            icon={<DeleteIcon />}
                            style={
                                {
                                    border: 'none',
                                    color:config.disableDelete? 'grey': '#377EF3'
                                }
                                }
                            />
                        </PermissionChecker>
                    </CustomConfirmPopup> : null}
            </Col>
        </Row>;

        if(config.buildCardHeader){
            childContent = config.buildCardHeader(item)
        }
        return childContent || content;
    }

    const buildCardBody = (item, index) =>{
        let childContent = null;
        let innerContents = [];
        if(config.cardBodyFields) {
            for (const key in config.cardBodyFields) {
                const nestedKeys = key.split(".");
                let value = item;
                for (const nestedKey of nestedKeys) {
                    value = value[nestedKey];
                    if (value === undefined) {
                        break;
                    }
                }
                innerContents.push(
                    <Row key={key} style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <Tooltip title={`${config.cardBodyFields[key]}: ${value}`}>
                            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {config.cardBodyFields[key]}: {value}
                            </span>
                        </Tooltip>
                    </Row>
                )
            }
        }
        const rightColumnContent = (
            <Row style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Tooltip title={`${t('last_edit_label')}: ${item.updated_by_text}`}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {t('last_edit_label')}: {item.updated_by_text}
                    </span>
                </Tooltip>
            </Row>
        );
        const content = <Row style={{ display: 'flex', width: '100%' }} key={'cardContent'}>
            <Col span={12} style={{ paddingRight: '8px' }}>
                <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
                    {innerContents}
                </Row>
            </Col>
            <Col span={12} style={{ paddingLeft: '8px' }}>
                <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
                    {rightColumnContent}
                </Row>
            </Col>
        </Row>
        if(config.buildCardBody){
            childContent = config.buildCardBody(item)
        }
        return childContent || content;
    }

    const buildFooterFields = (item) => {
        let footerFields = null;
        if(config.buildFooterFields){
            footerFields = config.buildFooterFields(item)
        }
        return footerFields || config.cardFooterFields;
    }

    const buildCardFooter=(item, gutter, index)=>{
        let childContent = null;
        const footerFields = buildFooterFields(item)
        let content = <Row style={{ marginTop: gutter, alignItems:'end'}}>
            <Col span={18} key={'cardFooterFields'}>
                {footerFields ? footerFields.map((field, index)=>{
                    return <><span key={index}>{field}</span> {index !== footerFields.length-1 ? <Divider type={'vertical'}/>:null}</>
                }): null}
            </Col>
            {!config.hideDetailButton ? <Col span={4} key={'cardFooterButton'}>
                <PermissionChecker requiredPermissions={config.viewPermission || []}>
                    <Button type="primary" style={{height: '40px', background:'#377EF3'}} onClick={()=>onDetailButtonClicked(item)}>
                        {config.cardDetailButtonText}
                    </Button>
                </PermissionChecker>
            </Col>:null}
        </Row>
        if(config.buildCardFooter){
            childContent = config.buildCardFooter(item)
        }
        return childContent || content;
    }


    const renderCards = () => {
        // const cardCols = window.innerWidth < 1441 ? 3 : 4; // Number of columns for cards
        const gutter = 16; // Space between cards

        return (
            <Row gutter={gutter} style={{ height: '78%', marginTop: '10px', overflowY: 'auto', justifyContent: listData.length === 0 ? 'center' : '' }} key="card">
                {listData.length !== 0 ? listData.map((item, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={8}
                        xxl={6}
                        // style={{ display: 'flex', justifyContent: 'center' }} // Center cards in column
                    >
                        <Card
                            hoverable
                            style={{ marginBottom: gutter, background:getCardBackgroundColor(item, index) }}
                        >
                            {buildCardHeader(item, index)}
                            <Divider />
                            {buildCardBody(item, index)}
                            {buildCardFooter(item, gutter, index)}
                        </Card>
                    </Col>
                )) : (
                    <div style={{ textAlign: 'center', margin: 'auto', fontWeight: 'bold', fontSize: '20px' }}>{t('no_record_text')}</div>
                )}
            </Row>
        );
    };

    return (
        <div style={{height: '100%', width: '100%'}}>
            {renderHeader()}
            <Divider />
            {renderCards()}
            {/*<div style={{ textAlign: 'center', height: '10%', background:'white', marginBottom: '10px'}}>*/}
            {/*    <Pagination style={{paddingTop: '10px'}} current={currentPage} total={total} pageSize={pageSize} onChange={handlePageChange} />*/}
            {/*</div>*/}
        </div>
    );
};
ListPage.propTypes = {
    config: PropTypes.shape({
        url: PropTypes.string.isRequired,
        pageTitle: PropTypes.string,
        cardTitleField: PropTypes.string,
        searchPlaceHolder: PropTypes.string,
        cardDetailButtonText: PropTypes.string,
        cardDeleteButtonText: PropTypes.string,
        cardBodyFields: PropTypes.object,
        cardFooterFields: PropTypes.arrayOf(PropTypes.node),
        buildPageHeader: PropTypes.func,
        buildCardHeader: PropTypes.func,
        buildCardBody: PropTypes.func,
        buildCardFooter: PropTypes.func,
        buildFooterFields: PropTypes.func,
    }),
    onAddClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onSearchClick: PropTypes.func,
    onPageChange: PropTypes.func,
    onDetailClick: PropTypes.func
};
export default ListPage;
