import HomePage from "./pages/HomePage/HomePage";
import PermissionDenied from "./PermissionDenied";
import LoginPage from "./pages/LoginPage/LoginPage";
import UserListPage from "./pages/Users/UserListPage";
import UserPermission from "./pages/Users/UserPermission";
import BranchListPage from "./pages/Branches/BranchListPage";
import ProjectListPage from "./pages/Projects/ProjectListPage";
import ProjectDetailPage from "./pages/Projects/ProjectDetailPage";
import MachineListPage from "./pages/Machines/MachineListPage";
import MachineDetailPage from "./pages/Machines/MachineDetailPage";
import ContentNotFound from "./ContentNotFound";
import ManifestDetailPage from "./pages/Manifests/ManifestDetailPage";
import AuthAppListPage from "./pages/AuthApps/AuthAppListPage";
import APIServerListPage from "./pages/APIServers/APIServerListPage";
import APIEventListPage from "./pages/APIEvents/APIEventListPage";
import APITaskListPage from "./pages/APITasks/APITaskListPage";
import SystemInfoListPage from "./pages/SystemInfos/SystemInfoListPage";
import UserDetailPage from "./pages/Users/UserDetailPage";
import UserProfilePage from "./pages/Users/UserProfilePage";
import GeneralSettings from "./pages/Settings/Settings";
import ErrorLogPage from "./pages/ErrorLogs/ErrorLogs"


const routes = [
    {
        name: 'Login',
        path: '/login',
        exact: true,
        component: LoginPage,
        requiredPermission: null
    },
    {
        name: 'Home',
        path: '/',
        exact: true,
        component: UserProfilePage,
        requiredPermission: ['user-detail']
    },
    {
        name: 'Projects',
        path: '/projects',
        exact: true,
        component: ProjectListPage,
        requiredPermission: ['project-list']
    },
    {
        name: 'Project Detail',
        path: '/project/detail/',
        exact: true,
        component: ProjectDetailPage,
        requiredPermission: ['project-detail']
    },
    {
        name: 'Users',
        path: '/users',
        exact: true,
        component: UserListPage,
        requiredPermission: ['user-list']
    },
    {
        name: 'User Permission',
        path: '/user_permissions',
        exact: true,
        component: UserPermission,
        requiredPermission: ['system_urls']
    },
    {
        name: 'Branch List',
        path: '/branches',
        exact: true,
        component: BranchListPage,
        requiredPermission: ['branch-list']
    },
    {
        name: 'Machine List',
        path: '/machines',
        exact: true,
        component: MachineListPage,
        requiredPermission: ['machine-list']
    },
    {
        name: 'Machine Detail',
        path: '/machine/detail/',
        exact: true,
        component: MachineDetailPage,
        requiredPermission: ['machine-detail']
    },
    {
        name: 'Manifest Detail',
        path: '/manifest/detail/',
        exact: true,
        component: ManifestDetailPage,
        requiredPermission: ['manifest-detail']
    },
    {
        name: 'Auth Apps',
        path: '/auth_apps/',
        exact: true,
        component: AuthAppListPage,
        requiredPermission: ['auth-apps-list']
    },
    {
        name: 'API Servers',
        path: '/api_servers/',
        exact: true,
        component: APIServerListPage,
        requiredPermission: ['api-servers-list']
    },
    {
        name: 'API Events',
        path: '/api_events/',
        exact: true,
        component: APIEventListPage,
        requiredPermission: ['api-events-list']
    },
    {
        name: 'API Tasks',
        path: '/api_tasks/',
        exact: true,
        component: APITaskListPage,
        requiredPermission: ['api-tasks-list']
    },
    {
        name: 'System Infos',
        path: '/system_infos/',
        exact: true,
        component: SystemInfoListPage,
        requiredPermission: ['system_info']
    },
    {
        name: 'PermissionDenied',
        path: '/permission_denied',
        exact: true,
        component: PermissionDenied
    },
    {
        name: 'Content Not Found',
        path: '/content_not_found',
        exact: true,
        component: ContentNotFound
    },
    {
        name: 'User Detail',
        path: '/user/detail',
        exact: true,
        component: UserDetailPage,
        requiredPermission: ['user-detail']
    },
    {
        name: 'User Profile',
        path: '/user/profile',
        exact: true,
        component: UserProfilePage,
        requiredPermission: ['user-detail']
    },
    {
        name: "Settings",
        path: '/settings',
        exact: true,
        component: GeneralSettings
    },
    {
        name: "Error Logs",
        path: '/error_logs',
        exact: true,
        component: ErrorLogPage,
        requiredPermission: ['error_logs']
    }
]

export default routes;
