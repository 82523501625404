import DataTable from "../../components/DataTable/DataTable";
import {Tooltip} from "antd";


const ErrorLogPage = () => {

    const columns = [
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            width: '15%',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            width: '15%',
            ellipsis: true,
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text}</div>
            ),
        },
        {
            title: 'Error',
            dataIndex: 'error',
            key: 'error',
            width: '50%',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text}</div>
                </Tooltip>
            ),
        },
    ];

    return (
            <DataTable
                reload={true}
                pageTitle={'Error Logs'}
                columns={columns}
                url="/api/v1/error_logs/"
                pagination={true}
                hideHeader={true}
            />
    );
};

export default ErrorLogPage;