import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import http from "../../http";
import {CaretDownOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const { Option } = Select;

const SelectField = ({
                       url='',
                       data=[],
                       searchField = 'name',
                       placeholder,
                       onSelect = null,
                       value= null,
                       className,
                       debounceTimeout,
                       onChange = null,
                       mode = 'single',
                       queryParams = {},
                       style = {},
                       reload=false,
                       disabled=false,
                       withTranslation = false
                     }) => {
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const { t } = useTranslation();
  const fetchOptions = (value) => {
    setLoading(true);
    try {
      queryParams[searchField] = value;
      http.get(url, {params: queryParams}).then((data)=>{
        const newOptions = data?.data?.reduce((acc, curr) => {
          acc.push({
            label: withTranslation ? t(curr.name): curr.name,
            value: curr.id
          });
          return acc;
        }, []);
        if(value){
          setFilteredOptions(newOptions)
        }
        else{
          setOptions(newOptions)
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const debounceFetcher = React.useMemo(() => {
  //   return debounce(fetchOptions, debounceTimeout);
  // }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    if (data && data.length > 0) {
      const newOptions = data.reduce((acc, curr) => {
        acc.push({
          label: withTranslation ? t(curr.name): curr.name,
          value: curr.id
        });
        return acc;
      }, []);
      if(searchValue){
        setFilteredOptions(newOptions)
      }
      else {
        setOptions(newOptions)
      }
    } else if (url) {
      fetchOptions(searchValue);
    }

  }, [searchValue, reload, value]);

  const handleSearch = (value) => {
    setSearchValue(value);
    // // if (url && value) {
    // //   debounceFetcher(value);
    // // } else
    //   if (data && data.length > 0) {
    //   const filteredOptions = data.filter((option) => {
    //         return option.name.toLowerCase().includes(value.toLowerCase())
    //       }
    //   );
    //   const newOptions = filteredOptions.reduce((acc, curr) => {
    //     acc.push({
    //       label: curr.name,
    //       value: curr.id
    //     });
    //     return acc;
    //   }, []);
    //   setFilteredOptions(newOptions);
    // }
  };

  const handleSelect = (value, option) => {
    if(onSelect) {
      onSelect(value, option.props.children);
    }

  };
  function handleChange(newValue) {
    setSelectedValue(newValue);
    if (onChange) {
      if (mode === 'single') {
        onChange(newValue);
      } else if (mode === 'multiple') {
        onChange(newValue || []);
      }
    }
  }
  const handleClear = () => {
    setSearchValue(null);
  };
  const handleOnClick=()=>{
    setSearchValue(null);
  }

  const getPopupContainer = (trigger) => {
    return trigger.parentNode;
  };

  function renderSelect(){
    const optionsToRender = searchValue ? filteredOptions : options;
    return       <Select
        // name={name}
        // defaultValue={defaultValue}
        allowClear={true}
        showSearch
        value={selectedValue || ((selectedValue !== undefined && !value && !value === 0) ? selectedValue : value !==
            undefined?
            value : undefined)}
        // initialValue={selectedValue || undefined}
        placeholder={placeholder}
        onClear={handleClear}
        onSelect={handleSelect}
        filterOption={false}
        onChange={handleChange}
        onSearch={handleSearch}
        className={className}
        onClick={handleOnClick}
        mode={mode}
        notFoundContent={loading ? 'Loading...' : t('dropdown_no_data_text')}
        suffixIcon={<CaretDownOutlined />}
        getPopupContainer={getPopupContainer}
        style={{width: 200, ...style}}
        dropdownRender={menu => (
            <div className="custom-select-dropdown">
              {menu}
            </div>
        )}
        disabled={disabled}
    >
      {
        optionsToRender.map((item)=>{
          return item ?  <Option key={item.value} value={item.value} className="custom-select-option">
            {item.label}
          </Option> : null
        })
      }
    </Select>
  }
  return (
      renderSelect()
  );
};

SelectField.propTypes = {
  url: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  debounceTimeout: PropTypes.number,
  mode: PropTypes.string,
};

SelectField.defaultProps = {
  placeholder: 'Select...',
  debounceTimeout: 500,
};

export default SelectField;
